.resumeStyle {
    min-width:500px;
    max-width: 70%;
    /*border:solid green;*/

    margin-left: auto;
    margin-right: auto;
    background-color: rgb(223, 243, 255);
    padding-top: 5px;
    padding-bottom: 40px;

    display:block;
    /* padding: 10px; */
}

.resumeStyle__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }

.all-page-container {
    border: red solid 2px;
    background-color: black;
    
    height: 100%;
    max-height: 500px;
    overflow: auto;
    /* width: 100% !important;
    height: auto !important; */
}

.react-pdf__Page {
    margin-top: 10px;
  }
  .react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
  }
  
  .react-pdf__Page__annotations.annotationLayer {
    padding: 10px;
  }
  
  .react-pdf__Page__canvas {
    margin: 0 auto;
  }
  
  .resumeStyle .current{
    background-color: white;
    padding: .5em .5em 1em .5em;
    border-radius: 15px 15px 0px 0px;
    
  }

  .resumeStyle .currentOff{
    background-color: rgb(144, 144, 144);
    padding: .5em .5em 1em .5em;
    border-radius: 15px 15px 0px 0px;
    color: white;
  }

  .resumeStyle img{
    /* margin-left: auto;
    margin-right: auto; */
    width: 90%;
    border: red none 2px;
    margin-top: 10px;
    margin-bottom:30px;
  }

/* .single-page.button {
    padding: 20px;
    background-color: rgb(235, 208, 208);
} */

  /* .resumeStyle .downStyle{
    border-style:outset; 
    color: black; 
    background-image:linear-gradient(to bottom right, cyan, rgb(5, 223, 223)); 
    margin-bottom:20px;
    padding:10px;
    border-radius:15px 15px 15px 15px; 
    text-decoration:none;
  } */