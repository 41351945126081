.button{
    padding: 10px;
    background-color: rgb(235, 208, 208);
    border-radius: 10px;
    margin: 15px 5px 15px 5px;
    cursor: pointer;
  }

/* .button:hover {
    background-color: rgb(92, 92, 92);
    color: white;
} */