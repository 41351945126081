

.worksAnimPage{
    margin-left: auto;
    margin-right: auto;
    /* width: 1000px; */
    /* width: 1000px; */
    width: 95%;
    background-color: rgb(205, 243, 241);
    padding-top: 5px;
    padding-bottom: 10px;
    border:green solid 2px;
}
.worksAnimePage1a{
    /*border-style: solid;
    background-color: rgba(217, 240, 255, 0.63);*/
    margin-bottom:50px;
    width:95%;
    margin-left:auto;
    margin-right:auto;
    border:none black 1px;
    border-radius: 25px;
}

.conceptA{
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    border: yellow none 2px;
    background: gray;
    border-radius: 10px;
    padding-bottom: 25px;
    margin-bottom: 50px;
    margin-top:20px;
    text-align:center;
}



.conceptA h2{
    border: black none 2px;

    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 10px 10px 0px 0px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

/* .conceptA p{
    border: green solid 2px;
    margin-top: 0px;
    padding-bottom: 5px;
    margin-bottom: 0px;
} */

.button1a{
    margin: "7px"; 
    padding:"10px"; 
    border:"2px solid";
    
}
.button1a:hover{
    background-color:"gray";
    color:"white";
    border:"3px solid white";
}