.titleBox1{
    background-color:"yellow";
    border:"1px solid gray";
    border-style:"groove";
    padding:"0 0 10px 0"
} 
.titleBox1:hover{
    background-color:"yellow";
    border:"1px solid gray";
    border-style:"groove";
    padding:"0 0 10px 0"
} 