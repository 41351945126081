

.profileImg{
    margin-top: 20px;
    width: 23%;
    background-color: rgb(238, 238, 238);
    padding: 1px;
    vertical-align: top;
    /* border: red solid 4px; */
}

.aboutPageSub{
    /*border-style: solid;
    background-color: rgba(217, 240, 255, 0.63);*/
    margin-top:50px;
    margin-bottom:50px;
    width:95%;
    margin-left:auto;
    margin-right:auto;
    border:none black 1px;
    border-radius: 25px;
    
}

/* .aboutPage h1{
    font-weight: 500;
    line-height: 1.2;
} */

/* #aboutTopStyle {
    text-align:left;
    
} */

.fStyle{
    /* margin-top: 50px;
    margin-bottom: 50px;
    font-weight: 100px;
    line-height: 20px; */
    /* margin-top: 50px;
    margin-bottom: 50px; */
    display: inline-block;
    text-align: left;
    width: 40%;
    font-size: 30px;
    margin-left: 4%;
    margin-right: 10%;
    border: black solid 2px;
    background: rgba(228, 228, 228, .9);
    margin-top: 30px;
    padding-left: 30px;
    border-radius: 15px 15px 15px 15px;
    /* border: red solid 4px; */
    
}


.aboutStyle{
    width: 90%;
    margin-bottom: 50px;
    background-color: rgb(255, 255, 255);
    margin-left:"auto";
    margin-right:"auto";
    border-radius: 10px;
    border: black solid 2px;
    padding:10px;
}


.aboutStyle2{
    margin-top: 20px;
    width: 90%;
    margin: "auto";
    
    /*background-color: rgb(184, 184, 184);*/
    background-image: linear-gradient(to bottom, rgba(244, 244, 244, 0.8), white);
    border-radius: 10px;
    border: black solid 2px;
    padding: 10px;
}

/* .h2_skills{
    background-color:gray; 
    border: red solid 2px;
    
} */

/* .aboutStyle .eachSkill1{
    background-color: gray;
    border: black solid 3px;
    width: 250px;
    display: flex;
    
} */



.aboutStyle h3{
    padding-left: 10px;
    /* font-size:20px; */
    /* font-weight:150; */
    line-height: 1;
    /* border:blue solid 2px; */
    /* vertical-align: top; */
    /* display: inline-block; */
}

.title{
    /* background-image: linear-gradient(to bottom right, rgb(207, 207, 207, .8), rgb(149, 149, 149, 08)); */
    /* background-color: white; */
    border: 2px solid;
    font-style: bold;
    background-color:white;
    margin-top: 0px;
    /* background-color: gray; */
    /* border-radius: 5px; */
    text-align:center;
    padding:10px;
    margin-bottom: 0px;
    /* font-weight: 500;
    line-height: 1.2; */
}

.title2{
    /* background-image: linear-gradient(to bottom right, rgb(207, 207, 207, .8), rgb(149, 149, 149, 08)); */
    background-color: rgb(184, 184, 184);
    font-style: bold;
    margin-top: 0px;
    /* border: white solid 2px; */
    border-radius: 25px 25px 0px 0px;/* background-image: linear-gradient(to bottom right, rgb(189, 216, 238), rgb(129, 175, 236)); */
    color: white;
    /* background-color: gray; */
    /* border-radius: 5px; */
    padding: 10px;
    margin-bottom: 0px;
    text-align:center;
}

.content1{
    /* padding:0px; */
    /* background-color: orange; */
    background-image: linear-gradient(to bottom, rgb(255, 255, 100, .7), rgb(251, 251, 174, .7));
    border: black solid 2px;
    border-radius:0px 0px 5px 5px;
    padding: 0px 30px 0px 30px;
    box-shadow: 0 0 15px #ccc;
    min-width:300px;
    width:70%;
    margin:auto;
    /* margin-left: auto;
    margin-right: auto; */
    /* width: 800px; */
}

.content2{
    /* padding:0px; */
    /* background-color: orange; */
    background-image: linear-gradient(to bottom, rgb(255, 255, 100, .7), rgb(251, 251, 174, .7));
    border: black solid 2px;
    border-radius:0px 0px 5px 5px;
   
    /* margin-left: auto;
    margin-right: auto; */
    /* width: 800px; */
}

.content p{
    margin: 10px;
    /* margin-bottom: 10px; */
    font-size: 15px;
    padding: 4px;
}

.skills{
    background-color: white;
    display: flex;
    /* width: 800px; */
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
    /* margin:0px; */
    /* width: 100%; 
    border:2px solid;*/
    background-color: rgba(253, 253, 253, 0.7);
}

.skills2{
    /*background-color: rgb(184, 184, 184);*/
    display: flex;
    /* width: 800px; */
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
    border-radius: 10px 10px 10px 10px;
    /* margin:0px; */
    /* width: 100%; 
    border:2px solid;*/
    background-color:rgba(245, 245, 245, 0.7);
}

.contain1{
    background-color: "rgba(255, 255, 255, 0.7)"; 
    border: "yellow solid 2px"; 
    margin-left:"auto"; 
    margin-right:"auto"; 
    padding-top:"20px"; 
    min-width:"400px";
    max-Width: "94%"; 
    display: "block";
}

.skillImg{
    width:40px;
    height:40px;
}

.skillName{
    padding-left:10px; 
    top:50%; 
    transform:translate(0, -50%);  
}

.titleBackground{
        display:flex; 
        flex-wrap:wrap; 
        justify-content:space-between; 
        padding-bottom:20px;  
        padding-top:20px; 
        /*background-color:black;*/
        background-image:linear-gradient(to right, rgba(255, 250, 159, 0.7), rgba(245, 245, 245, 0.7));
}

.updateBackground{
    /*background-image:linear-gradient(to bottom, #c9e5ff, #a3e3ff);
    box-shadow:0 2px 4px 0;
    border:gray solid 1px; 
    border-radius:10px;*/
    
    /*border-top:1px solid;*/
    border-bottom:1px solid;
    padding:10px 10px 40px 10px; 
    margin:auto;
    /*width:90%;*/
  }

