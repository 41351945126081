footer{
    background-image: linear-gradient(to top right, rgb(129, 154, 236, .5), rgb(73, 167, 255, .5));
    width: 100%;
    border: black none 2px;
    margin-left: auto;
    margin-right: auto;
    /*padding: 0 25px;*/
}

.footerStyle{
    display: flex;
    margin:0 auto;
    border: yellow none 2px;
    
    /*justify-content: center;*/
    /* text-align: center; */
    
    width: 100%;
}

.footerStyle h1{
    width: 50%;
    text-align: left;
}

.wrap{
    /* text-align:right; */
    border: red none 2px;
    width: 60%;
}

.wrap h3{
    /* padding:5px; */
    /* margin: 5px; */
    text-align: right;
}